import {
  closestCorners,
  DndContext,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import axios from "axios";
import ActionButton from "components/common/ActionButton";
import FalconLightBox from "components/common/FalconLightBox";
import Flex from "components/common/Flex";
import { useFetchCategories } from "hooks/useFetchCategories";
import { useFetchDetailsFieldSets } from "hooks/useFetchDetailFieldSets";
import { useFetchVariants } from "hooks/useFetchDetailVariants";
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Col,
  FloatingLabel,
  Form,
  Image,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { useFieldArray, useFormContext } from "react-hook-form";
import { FaImage } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { customSettings } from "utils/customConfig";
import { components } from "react-select";
import CustomOption from "utils/CustomOption";

const SortableItem = ({ id, children }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <tr ref={setNodeRef} style={style}>
      <td
        {...listeners}
        {...attributes}
        style={{
          cursor: "grab",
          textAlign: "center",
          width: "10px",
          // paddingTop: "15px",
        }}
      >
        ☰
      </td>
      {children}
    </tr>
  );
};

const CategoryGroupBasicInformation = () => {
  const {
    register,
    setValue,
    clearErrors,
    control,
    watch,
    trigger,
    setError,
    formState: { errors, isDirty },
  } = useFormContext();

  const {
    fields: categories,
    append: appendCategories,
    remove: removeCategories,
    move,
  } = useFieldArray({
    control,
    name: "categories",
  });

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      },
    })
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      const oldIndex = categories.findIndex((item) => item.id === active.id);
      const newIndex = categories.findIndex((item) => item.id === over.id);
      move(oldIndex, newIndex);
    }
  };

  const { state } = useLocation();
  const [file, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const nameWatch = watch("name");
  const catWatch = watch("categories");
  const maxCharactersLength = 255;

  console.log(catWatch);

  const { data: categoriesData, isLoading: categoriesLoading } =
    useFetchCategories();

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: {
      "image/*": [".jpeg", ".png", ".jpg"],
    },
    onDrop: async (acceptedFiles) => {
      const uploadedFile = acceptedFiles[0];
      const uploadedFileUrl = await uploadFile(uploadedFile);
      setValue("logo", uploadedFileUrl);
      setFile(
        Object.assign(uploadedFile, {
          preview: URL.createObjectURL(uploadedFile),
        })
      );
    },
  });

  const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      setLoading(true);
      const response = await axios.post("/Catalogue/CategoryFile", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setLoading(false);

      // Assuming the response contains the URL of the uploaded file
      return response.data[0].destination;
    } catch (error) {
      console.error("File upload failed:", error);
      toast.error("Failed to File Upload", {
        position: customSettings.toastPosition,
        autoClose: customSettings.toastClose,
      });
      return "";
    }
  };

  const handleSelectChange = (selectedOption, index) => {
    console.log("Selected option", selectedOption);

    const selectedValue = selectedOption ? selectedOption.value : "";

    console.log("Selected Value", selectedValue);

    // If no option is selected (value is cleared)
    if (!selectedValue) {
      // Clear both the value and error
      setValue(`categories[${index}].categoryId`, "");
      clearErrors(`categories[${index}].categoryId`);
      return;
    }

    const selectedOptionData = flatOptions.find(
      (option) => option.value === selectedValue
    );

    if (!selectedOptionData) {
      console.error("Selected option data not found.");
      return;
    }

    // Convert hierarchical data into a flat map for quick lookup
    const hierarchicalMap = new Map();
    const traverse = (node) => {
      hierarchicalMap.set(node.value, node);
      node.children.forEach(traverse);
    };
    hierarchicalDataForValidations.forEach(traverse);

    const getAncestors = (categoryId) => {
      const ancestors = [];
      let current = hierarchicalMap.get(categoryId);

      while (current && current.parent !== 0) {
        current = hierarchicalMap.get(current.parent);
        if (current) ancestors.push(current.value);
      }

      return ancestors;
    };

    // Get all descendants of a category
    const getDescendants = (categoryId) => {
      const descendants = [];
      const node = hierarchicalMap.get(categoryId);
      if (node) {
        const traverseDescendants = (n) => {
          n.children.forEach((child) => {
            descendants.push(child.value);
            traverseDescendants(child);
          });
        };
        traverseDescendants(node);
      }
      return descendants;
    };

    // Validation logic to check for duplicates, ancestors, and descendants
    const isDuplicate = categories.some(
      (item, idx) => idx !== index && item.categoryId === selectedValue
    );
    const hasDescendantInGroup = categories.some((item) => {
      const itemDescendants = getDescendants(item.categoryId);
      return itemDescendants.includes(selectedValue);
    });
    const hasAncestorInGroup = categories.some((item) => {
      const itemAncestors = getAncestors(item.categoryId);
      return itemAncestors.includes(selectedValue);
    });

    if (isDuplicate || hasDescendantInGroup || hasAncestorInGroup) {
      // Set empty value if there is any conflict
      setValue(`categories[${index}].categoryId`, "");
      setError(`categories[${index}].categoryId`, {
        type: "manual",
        message: isDuplicate
          ? "This category is already added."
          : hasDescendantInGroup
          ? "A descendant is already selected."
          : "An ancestor is already selected.",
      });
    } else {
      // Clear errors and set the selected value
      clearErrors(`categories[${index}].categoryId`);
      setValue(`categories[${index}].categoryId`, selectedValue);
    }
  };

  const handleInputFocus = (index) => {
    clearErrors(`categories[${index}].categoryId`);
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      cursor: "pointer",
      borderColor: errors?.categories?.[state.selectProps.index]?.categoryId
        ? "red"
        : "#dbdbdb",
      "&:hover": {
        borderColor: errors?.categories?.[state.selectProps.index]?.categoryId
          ? "red"
          : provided["&:hover"].borderColor,
      },
    }),
    input: (provided) => ({
      ...provided,
      color: "black",
      paddingTop: "4px",
      paddingBottom: "4px",
    }),
    option: (provided, state) => ({
      ...provided,
      paddingLeft: `${state.data.level * 20}px`,
    }),
    menu: (provided) => ({
      ...provided,
      height: 140,
      color: "black",
      zIndex: 1, // High enough to ensure visibility
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      height: 140,
      zIndex: 1, // High enough to ensure visibility
    }),
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 1,
    }),
  };

  const flattenOptions = (nodes, level = 1) => {
    return nodes.reduce((acc, node) => {
      const label = `${"".repeat(level - 0)} ${node.name}`;
      acc.push({
        id: node.id,
        value: node.id,
        label,
        parent: node.parent,
        level,
      });

      if (node.children && node.children.length > 0) {
        acc.push(...flattenOptions(node.children, level + 1));
      }

      return acc;
    }, []);
  };

  const createHierarchy = (data) => {
    const lookup = {};
    const result = [];

    data?.forEach((item) => {
      lookup[item.id] = { ...item, children: [] };
    });

    data?.forEach((item) => {
      const { id, parent } = item;
      if (parent === 0) {
        result.push(lookup[id]);
      } else if (lookup[parent]) {
        lookup[parent].children.push(lookup[id]);
      }
    });

    return result;
  };

  const transformFlatToHierarchical = (flatOptions) => {
    const map = new Map();

    // Initialize all categories in the map
    flatOptions.forEach((option) => {
      map.set(option.value, { ...option, children: [] });
    });

    const root = [];

    // Build hierarchical structure
    flatOptions.forEach((option) => {
      if (option.parent === 0) {
        root.push(map.get(option.value));
      } else {
        const parent = map.get(option.parent);
        if (parent) {
          parent.children.push(map.get(option.value));
        }
      }
    });

    return root;
  };

  const hierarchicalData = createHierarchy(categoriesData?.data?.data);
  // console.log("hierarchicalData", hierarchicalData);
  const flatOptions = flattenOptions(hierarchicalData);
  // console.log("flatOptions", flatOptions);

  // const cleanedData = flatOptions.map((data) => ({
  //   ...data,
  //   value: String(data.value), // Convert the value to a string
  // }));

  // console.log(cleanedData);

  const hierarchicalDataForValidations =
    transformFlatToHierarchical(flatOptions);

  // console.log(hierarchicalDataForValidations);

  useEffect(() => {
    if (state) {
      // setValue("name", state.name);
      // setValue("description", state.description);
      // setValue("status", state.status);
      const initialCategories = state.categories.map((cat) => ({
        id: cat.categoryId,
        categoryId: cat.categoryId,
        // additional fields if necessary
      }));
      initialCategories.forEach((category) => appendCategories(category));
    }
  }, [state, setValue, appendCategories]);

  console.log(errors.logo);

  return (
    <div className="">
      {categoriesLoading ? (
        <div className="d-flex py-3 justify-content-center align-items-center">
          <Button variant="falcon-default" size="sm" className="fs-10">
            Loading...
          </Button>
        </div>
      ) : (
        <Card.Body
          className=""
          style={{
            height: `calc(100vh - 8.4rem)`,
            overflow: "auto",
            padding: "5px 20px",
            // paddingRight: "7px",
            zIndex: 1,
          }}
        >
          <Row className="gx-2">
            <Col md="5">
              <Form.Group>
                <Form.Control
                  type="text"
                  placeholder="Name"
                  title="Name"
                  className="py-2"
                  maxLength={maxCharactersLength}
                  isInvalid={!!errors.name}
                  {...register("name")}
                />

                <div className="d-flex justify-content-between align-items-center">
                  <Form.Control.Feedback
                    type="invalid"
                    className="d-inline w-75"
                  >
                    {errors.name?.message}
                  </Form.Control.Feedback>
                  <small className="fs-10 text-muted">
                    {`${nameWatch?.length || 0} / ${maxCharactersLength}`}
                  </small>
                </div>
              </Form.Group>
            </Col>
            <Col md={"3"}>
              {/* <Col md={file || state?.logoFile?.fileBase64 ? "2" : "3"}> */}
              <Form.Group>
                <Form.Control
                  type="number"
                  min={0}
                  placeholder="Display Order"
                  title="Display Order"
                  className="py-2"
                  isInvalid={!!errors.sortOrder}
                  {...register("sortOrder", {
                    // Additional validation
                    validate: (value) =>
                      value > 0 || "Order must be a positive number", // Custom validation message
                  })}
                  onChange={(e) => {
                    const value = e.target.value;
                    // Allow only positive integers or empty
                    if (value === "" || /^[0-9]\d*$/.test(value)) {
                      e.target.value = value; // Set the value if it's valid
                    } else {
                      e.target.value = ""; // Reset if invalid
                    }
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.sortOrder?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={"1"}>
              <div {...getRootProps()}>
                <input {...getInputProps({ multiple: false })} />
                <Flex justifyContent="center" alignItems="center">
                  <FaImage size={27} className="mt-2 cursor-pointer" />
                  {/* <p className="fs-9 mb-0 text-700"> Select Logo</p> */}
                </Flex>
                <p
                  style={{
                    fontSize: "12px",
                    color: "#e63757",
                    marginTop: "10px",
                    width: "120px",
                  }}
                >
                  {errors.logo?.message}
                </p>
              </div>
            </Col>
            {/* <Col md="2">
              {file ? (
                <FalconLightBox image={file.preview}>
                  <Image
                    src={file.preview}
                    // fluid
                    rounded
                    width={80}
                    height={40}
                  />
                </FalconLightBox>
              ) : (
                <FalconLightBox>
                  <Image
                    src={`data:image/${state?.logoFile?.fileBase64
                      ?.split(".")
                      .pop()};base64,${state?.logoFile?.fileBase64}`}
                    // fluid
                    rounded
                    width={80}
                    height={40}
                  />
                </FalconLightBox>
              )}
            </Col> */}

            <Col md={"2"} style={{ display: "flex", justifyContent: "center" }}>
              {/* <Col md={file || (state?.logoFile?.fileBase64 ? "2" : "1")}> */}
              {file || state?.logoFile?.fileBase64 ? (
                <FalconLightBox
                  image={
                    file
                      ? file.preview
                      : `data:image/${state.logoFile.fileBase64
                          .split(".")
                          .pop()};base64,${state.logoFile.fileBase64}`
                  }
                >
                  <Image
                    src={
                      file
                        ? file.preview
                        : `data:image/${state.logoFile.fileBase64
                            .split(".")
                            .pop()};base64,${state.logoFile.fileBase64}`
                    }
                    rounded
                    width={80}
                    height={40}
                  />
                </FalconLightBox>
              ) : null}
              {/* Render nothing if both file and state.logoFile.fileBase64 are not present */}
            </Col>

            <Col md="1" style={{ paddingRight: "0px" }}>
              <Form.Check
                type="checkbox"
                id="defaultSwitch"
                label="Active"
                className="mt-2 "
                defaultChecked={!state}
                isInvalid={!!errors.status}
                {...register("status")}
              />
            </Col>
          </Row>

          <Row className="gx-2 gy-3 ">
            <DndContext
              sensors={sensors}
              collisionDetection={closestCorners}
              onDragEnd={handleDragEnd}
            >
              <SortableContext
                items={categories.map((item) => item.id)}
                strategy={verticalListSortingStrategy}
              >
                <Table>
                  <thead className="bg-200">
                    <tr>
                      <th scope="col" className="w-0 "></th>
                      <th
                        scope="col"
                        className="w-0 fs-10 text-center"
                        style={{
                          textAlign: "center",
                          width: "10px",
                        }}
                      >
                        Order
                      </th>
                      <th
                        scope="col"
                        className="fs-10"
                        style={{ minWidth: "500px" }}
                      >
                        Category
                      </th>
                      {/* 
                      <th
                        style={{ width: "10px" }}
                        scope="col"
                        className="fs-10 text-center"
                      >
                        Status
                      </th> */}
                      <th
                        scope="col"
                        style={{ padding: "9px", width: "10px" }}
                        className=" text-end"
                      >
                        <ActionButton
                          icon="plus"
                          variant="success"
                          title="Add"
                          className="px-1 py-0"
                          onClick={() => appendCategories({})}
                        />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {categories.map((item, index) => (
                      <SortableItem key={item.id} id={item.id}>
                        <td style={{ padding: "13px" }} className="text-center">
                          {index + 1}
                          {/* {console.log(item)} */}
                        </td>
                        <td style={{ padding: "5px" }}>
                          <Form.Group>
                            <Select
                              // ref={optionRef}
                              options={flatOptions}
                              components={{ Option: CustomOption }}
                              isClearable={true}
                              placeholder={
                                <h6 className="mb-2 mb-md-0">Category</h6>
                              }
                              className={`${
                                errors?.categories?.[index]?.categoryId
                                  ? "is-invalid"
                                  : "basic-single"
                              }`}
                              styles={customStyles}
                              // getOptionLabel={(option) => option.label}
                              // getOptionValue={(option) => option.value}
                              defaultValue={
                                flatOptions.find(
                                  (option) => option.id == item.categoryId
                                ) || null
                              } // Handle empty selection
                              onChange={(selectedOption) =>
                                handleSelectChange(selectedOption, index)
                              }
                              menuPlacement="auto"
                              menuPortalTarget={document.body}
                              index={index}
                              onFocus={() =>
                                clearErrors(`categories.${index}.categoryId`)
                              } // Clear errors on focus
                              onBlur={() =>
                                trigger(`categories.${index}.categoryId`)
                              } // Revalidate on blur
                            />

                            <Form.Control.Feedback type="invalid">
                              {errors?.categories?.[index]?.categoryId?.message}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </td>
                        {/* <td style={{ padding: "5px" }} className="text-center">
                          <Form.Check
                            type="checkbox"
                            id="defaultSwitch"
                            className="mt-1"
                            defaultChecked={!state}
                            isInvalid={!!errors.categories?.[index]?.status}
                            {...register(`categories[${index}].status`)}
                          />
                        </td> */}
                        <td style={{ padding: "10px" }} className="text-center">
                          <ActionButton
                            icon="trash-alt"
                            title="Delete"
                            variant="danger"
                            className="px-1 py-0"
                            onClick={() => removeCategories(index)}
                          />
                        </td>
                      </SortableItem>
                    ))}
                  </tbody>
                </Table>
              </SortableContext>
            </DndContext>
          </Row>
        </Card.Body>
      )}
    </div>
  );
};

export default CategoryGroupBasicInformation;

// const CustomOption = (props) => {
//   const ref = useRef();

//   // Scroll to the option when it's selected
//   useEffect(() => {
//     if (props.isSelected) {
//       ref.current.scrollIntoView({ behavior: "smooth", block: "nearest" });
//     }
//   }, [props.isSelected]);

//   return <components.Option {...props} innerRef={ref} />;
// };

// export default CustomOption;
